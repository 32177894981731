// Name:            Slider
// Description:     Defines styles for a horizontal slider
//
// Component:       `uk-slider`
//
// Sub-objects:     `uk-slider-container`
//
// Modifier:        `uk-slider-fullscreen`
//
// States:          `uk-drag`
//
// Markup:
//
// <!-- uk-slider -->
// <div data-uk-slider>
//     <div class="uk-slider-container">
//         <ul class="uk-slider">
//             <li></li>
//             <li></li>
//         </ul>
//     </div>
// </div>
//
// ========================================================================

.uk-slider {
    position: relative;
    z-index: 0;
    touch-action: pan-y; }

.uk-slider:not(.uk-grid) {
    margin: 0;
    padding: 0;
    list-style: none; }

.uk-slider > * {
    position: absolute;
    top: 0;
    left: 0; }

.uk-slider-container {
    overflow: hidden; }

.uk-slider:not(.uk-drag) {
    -webkit-transition: -webkit-transform 200ms linear;
    transition: transform 200ms linear; }

.uk-slider.uk-drag {
    cursor: col-resize;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.uk-slider a,
.uk-slider img {
    -webkit-user-drag: none;
    user-drag: none;
    -webkit-touch-callout: none; }

.uk-slider img {
    pointer-events: none; }


.uk-slider-fullscreen,
.uk-slider-fullscreen > li {
    height: 100vh; }
